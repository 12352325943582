import App from './App.vue'
import Vue from 'vue'
import vuexI18n from 'vuex-i18n'
import translationRU from './translations/ru'
import translationKZ from './translations/kz'
import translationEn from './translations/en'
import translationBg from './translations/bg'
import store from '@/store/store'
import router from '@/router/router'
import ElementUI from 'element-ui'
import VDragged from 'v-dragged'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-material-design-icons/styles.css'
import '@/assets/leaflet.css'
import '@/assets/MarkerCluster.css'
import '@/assets/MarkerCluster.Default.css'
import 'vue-resize/dist/vue-resize.css'
import '@/assets/style.styl'
import '@/assets/styles/index.scss'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import kzLocale from 'element-ui/lib/locale/lang/kz'
import bgLocale from 'element-ui/lib/locale/lang/bg'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU.js'
import Axios from 'axios'
import './assets/tailwind.css'
import _ from 'lodash'
import VueHtmlToPaper from 'vue-html-to-paper'
import skifButton from '@/utils/skifButton'
import skifCheckbox from '@/utils/skifCheckbox'
import skifCheckboxOld from '@/utils/skifCheckboxOld'
import skifBadge from '@/utils/skifBadge'
import skifSearch from '@/utils/skifSearch'
import skifFilter from '@/utils/skifFilter'
import skifInputNumber from '@/utils/skifInputNumber'
import skifSwitch from '@/utils/skifSwitch'
import skifRadio from '@/utils/skifRadio'
import skifRadioOld from '@/utils/skifRadioOld'
import Vuelidate from 'vuelidate'
import { ResizeObserver } from 'vue-resize'
import { ColorPicker, ColorPanel } from 'one-colorpicker'
import VueVirtualScroller, { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@/assets/global.css'
import VueApexCharts from 'vue-apexcharts'
import './directives'
import vClickOutside from 'v-click-outside'

Vue.component('RecycleScroller', RecycleScroller)

Vue.use(ColorPanel)
Vue.use(ColorPicker)
Vue.use(VueVirtualScroller)
Vue.component('resize-observer', ResizeObserver)
Vue.component('RecycleScroller', VueVirtualScroller.RecycleScroller)
Vue.use(vClickOutside)
let logout

window.addEventListener('focus', _ => {
  if (store.getters['login/activeCompanyId'] === 'cad4c5e5-3f28-4715-91ac-41cb57fc32c3') {
    logout = setTimeout(() => {
      store.dispatch('login/logout').then(_ => router.push('login'))
    }, 1800000)
  }
})
window.addEventListener('blur', _ => {
  clearTimeout(logout)
})

Vue.component('skifRadio', skifRadio)
Vue.component('skifRadioOld', skifRadioOld)
Vue.component('skifButton', skifButton)
Vue.component('skifSwitch', skifSwitch)
Vue.component('skifInputNumber', skifInputNumber)
Vue.component('skifSearch', skifSearch)
Vue.component('skifFilter', skifFilter)
Vue.component('skifCheckbox', skifCheckbox)
Vue.component('skifCheckboxOld', skifCheckboxOld)
Vue.component('skifBadge', skifBadge)
Vue.component('apexchart', VueApexCharts)
Vue.use(VDragged)
Vue.use(Vuelidate)
Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes']
})

Vue.use(vuexI18n.plugin, store)

Vue.i18n.add('ru', { ...translationRU, ...ruLocale })
Vue.i18n.add('kz', { ...translationKZ, ...kzLocale })
Vue.i18n.add('en', { ...translationEn, ...enLocale })
Vue.i18n.add('bg', { ...translationBg, ...bgLocale })
Vue.i18n.set('ru')
locale.i18n((key, value) => Vue.i18n.translate(key, value))

Vue.use(ElementUI, { i18n: (key, value) => Vue.i18n.translate(key, value) })
Vue.use(VueApexCharts)

Vue.config.devtools = true

Vue.prototype.$showSuccess = _.debounce(function (msg) {
  this.$notify.success({
    duration: 5000,
    title: `${this.$t('success')} \u{1f642}`,
    message: msg
  })
}, 100)

Vue.prototype.$showError = _.debounce(function (error) {
  this.$notify.error({
    duration: 5000,
    title: `${this.$t('error')} \u{1f62a}`,
    message: error || `${this.getError}.` || 'error'
  })
}, 100)

Vue.prototype.$showAttention = _.debounce(function (error) {
  this.$notify.error({
    duration: 5000,
    title: `${this.$t('attention')} \u{1f62a}`,
    message: error || `${this.getError}.` || 'attention'
  })
})

Vue.prototype.$showNotification = _.debounce(function (header, message, setViewData) {
  this.$notify.info({
    duration: 5000,
    title: header,
    message,
    onClick() {
      store.commit('notifications/MARKER_ZOOM', setViewData)
    }
  })
}, 100)

Vue.prototype.$showChange = _.debounce(function (message) {
  this.$notify.info({
    duration: 5000,
    title: `${this.$t('change')} \u{1f64a}`,
    message
  })
}, 100)
if (window.location.href.includes('/approve')) {
  localStorage.setItem('isApproveUrl', 'true')
} else {
  localStorage.removeItem('isApproveUrl')
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

app.$mount('#app')

const id = localStorage.getItem('me')
if (id) {
  Axios.defaults.headers.common.Authorization = id
}

export { app }
